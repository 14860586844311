import { yupResolver } from "@hookform/resolvers/yup";
import { validateEmailCode } from "apis/auth.api";
import clsx from "clsx";
import { motion } from "framer-motion";
import React from "react";
import { useForm } from "react-hook-form";
import { displayErrorMessage } from "utils/messageHelper";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { useEmailPassword } from "./Login";

interface Inputs {
  emailCode: number;
}

const schema: SchemaOf<Inputs> = yup.object().shape({
  emailCode: yup.number().required().min(0).max(999_999),
});

export function EmailCodeVerification({
  onSuccessfulLogin,
}: {
  onSuccessfulLogin: Function;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emailAndPassword, _] = useEmailPassword();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ resolver: yupResolver(schema), mode: "onChange" });

  const onSubmit = (data: Inputs) => {
    validateEmailCode({
      email: emailAndPassword.email,
      password: emailAndPassword.password,
      emailCode: data.emailCode.toString(),
    })
      .then(() => onSuccessfulLogin())
      .catch(displayErrorMessage);
  };

  return (
    <motion.form
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -300, opacity: 0 }}
      transition={{ type: "spring", stiffness: 100 }}
      onSubmit={handleSubmit(onSubmit)}
      className="sm:w-2/3 w-full px-4 lg:px-0 mx-auto"
    >
      <div className="pb-2 pt-4">
        <input
          {...register("emailCode")}
          type="text"
          placeholder="Email code"
          minLength={6}
          maxLength={6}
          className={clsx(
            "block w-full p-4 text-lg rounded-sm bg-black",
            errors.emailCode && "border-red-500 border"
          )}
          title={errors.emailCode?.message}
        />
      </div>
      <div className="px-4 pb-2 pt-4">
        <button className="uppercase block w-full p-4 text-lg rounded-full bg-indigo-500 hover:bg-indigo-600 focus:outline-none">
          submit email verification code
        </button>
      </div>
    </motion.form>
  );
}
