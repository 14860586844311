import { AdminUser } from "./auth.api";
import { adminAxiosInstance } from "./axiosInstance";

export interface AdminUserFull {
  roles: string[];
  _id: string;
  email: string;
  superuser: boolean;
  partnerId: string;
  apiKey: string;
  createdAt: Date;
  updatedAt: Date;
}

export const getUsers = async (): Promise<AdminUser[]> => {
  return (await adminAxiosInstance.get("users")).data;
};

export const getUserByEmail = async (email: string): Promise<AdminUserFull> => {
  return (await adminAxiosInstance.get(`users/${email}`)).data;
};

export const getRoles = async () => {
  return (await adminAxiosInstance.get("users/roles")).data;
};

export const saveRoles = async (props: { email: string; roles: string[] }) =>
  adminAxiosInstance.put(`users/${props.email}/roles`, props.roles);

export const deleteUser = async (email: string): Promise<void> =>
  adminAxiosInstance.delete(`users/${email}`);
