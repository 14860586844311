import { PageHeader } from "antd";
import React from "react";

interface Props {
  text: string;
  subTitle?: string;
}

const PageTitle = (props: Props) => (
  <PageHeader
    title={props.text}
    subTitle={props.subTitle}
    className="bg-gray-900/50 mb-6"
  />
);

export default PageTitle;
