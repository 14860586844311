import { message } from 'antd'
import { AxiosError } from 'axios'

export const displayErrorMessage = (e: any) => {
  if (!!e.isAxiosError) {
    const errorData = (e as AxiosError);
    message.error(errorData.response?.data?.message ?? errorData.message)
  } else {
    message.error(e)
  }
}