import { yupResolver } from "@hookform/resolvers/yup";
import { login } from "apis/auth.api";
import clsx from "clsx";
import { motion } from "framer-motion";
import React from "react";
import { useForm } from "react-hook-form";
import { displayErrorMessage } from "utils/messageHelper";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { useEmailPassword } from "./Login";

interface Inputs {
  email: string;
  password: string;
}

const schema: SchemaOf<Inputs> = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required(),
});

export function LoginForm({
  onSuccessfulLogin,
}: {
  onSuccessfulLogin: Function;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setEmailPassword] = useEmailPassword();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ resolver: yupResolver(schema), mode: "onChange" });

  const onSubmit = (data: Inputs) => {
    setEmailPassword(data);
    login(data)
      .then(() => onSuccessfulLogin())
      .catch(displayErrorMessage);
  };

  return (
    <motion.form
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -300, opacity: 0 }}
      transition={{ type: "spring", stiffness: 100 }}
      onSubmit={handleSubmit(onSubmit)}
      className="sm:w-2/3 w-full px-4 lg:px-0 mx-auto"
    >
      <h1 className="text-5xl font-bold tracking-wide text-center mb-6">
        KYC Admin
      </h1>
      <div className="pb-2 pt-4">
        <input
          {...register("email")}
          type="email"
          placeholder="Email"
          className={clsx(
            "block w-full p-4 text-lg rounded-sm bg-black",
            errors.email && "border-red-500 border"
          )}
        />
        <span className="text-red-500 text-sm">{errors.email?.message}</span>
      </div>
      <div className="pb-2 pt-4">
        <input
          {...register("password")}
          type="password"
          placeholder="Password"
          className={clsx(
            "block w-full p-4 text-lg rounded-sm bg-black",
            errors.password && "border-red-500 border"
          )}
        />
        <span className="text-red-500 text-sm">{errors.password?.message}</span>
      </div>
      {/* <div className="text-right text-gray-400 hover:underline hover:text-gray-100">
        <Link to="#">Forgot your password?</Link>
      </div> */}
      <div className="px-4 pb-2 pt-4">
        <button className="uppercase block w-full p-4 text-lg rounded-full bg-indigo-500 hover:bg-indigo-600 focus:outline-none">
          sign in
        </button>
      </div>
    </motion.form>
  );
}
