import React from "react";

interface Props {}

const Home = (props: Props) => {
  return (
    <section className="text-gray-400">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap w-full p-4">
          <div className="w-full mb-6 lg:mb-0">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-white">
              KYC Admin 🏡
            </h1>
          </div>
          <p className="w-full leading-relaxed text-gray-400 text-opacity-90">
            Manage your KYC data, send invites to other Admin users, handle your
            Admin settings and many more!
          </p>
        </div>
      </div>
    </section>
  );
};

export default Home;
