import { notificationAxiosInstance } from "./axiosInstance";

export interface AdminNotification {
  _id: string;
  email: string;
  read: boolean;
  link: string;
  description: string;
  category: "KYC" | "other";
  createdAt: Date;
}

export const markAllAsRead = async (): Promise<void> =>
  notificationAxiosInstance.put(`notifications/read`);

export const markAsRead = async (id: string): Promise<void> =>
  notificationAxiosInstance.put(`notifications/read/${id}`);

export const getUnreadNotifications = async (): Promise<AdminNotification[]> =>
  (await notificationAxiosInstance.get(`notifications`)).data;
