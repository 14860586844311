import { AdminUserFull, getUserByEmail } from "apis/users.api";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { ServerState } from "utils/serverState";
import { useMe } from "hooks/Common.query";

export const useMeFull = () => {
  const { data, isSuccess } = useMe();

  return useQuery<AdminUserFull, AxiosError>(
    ServerState.ADMIN_USER_FULL,
    () => getUserByEmail(data!.email),
    { enabled: isSuccess }
  );
};
