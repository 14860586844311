import { DeleteOutlined } from "@ant-design/icons";
import { Tooltip, Button } from "antd";
import { AdminUser } from "apis/auth.api";
import AreYouSure from "components/shared/AreYouSure";
import React from "react";
import { useDeleteUser } from "./UserAdministration.query";

interface Props {
  record: AdminUser;
}

const DeleteUser = (props: Props) => {
  const { mutate } = useDeleteUser();
  return (
    <Tooltip title="Delete admin user">
      <AreYouSure onConfirm={() => mutate(props.record)}>
        <Button
          type="primary"
          danger
          shape="circle"
          icon={<DeleteOutlined />}
        />
      </AreYouSure>
    </Tooltip>
  );
};

export default DeleteUser;
