import { Typography } from "antd";
import CenteredSpin from "components/shared/CenteredSpin";
import ErrorResult from "components/shared/ErrorResult";
import PageTitle from "components/shared/PageTitle";
import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useMeFull } from "./ApiKey.query";

const { Title, Paragraph, Text } = Typography;

interface Props {}

const ApiKey = (props: Props) => {
  const { data, isError, isFetching, error } = useMeFull();

  if (isFetching) {
    return <CenteredSpin />;
  }
  if (isError) {
    return <ErrorResult error={error} />;
  }
  return (
    <>
      <PageTitle text="API Key" />
      <div className="space-y-5 text-base">
        <Title level={4}>What is an API Key in Peak's KYC system</Title>
        <div>
          <Paragraph type="secondary">
            The API key is a unique identifier that authenticates requests
            associated with your project for usage and billing purposes. <br />{" "}
            You have exactly one API key associated with your admin user.
          </Paragraph>
        </div>
        <div className="flex items-center flex-col space-x-2">
          <Text>
            ⚠ Keep this key in secret, do not share with anyone outside of your
            organization!
          </Text>
          <br />
          <Paragraph strong>
            API Key:
            <Text type="warning" className="asterisk ml-4" copyable>
              {data?.apiKey}
            </Text>
          </Paragraph>
        </div>
        <Title level={4}>Adding API Key to your KYC HTTP requests</Title>
        <div className="space-y-2">
          <Text type="secondary">
            Every KYC HTTP request should contain an <Text code>x-api-key</Text>{" "}
            HTTP header. <br />
            For example:
          </Text>
          <SyntaxHighlighter language="http" style={darcula} showLineNumbers>
            {`GET /kyc/api/v1/kyc HTTP/1.1\nHost: ${window.location.host}\nx-api-key: <YOUR-API-KEY>`}
          </SyntaxHighlighter>
        </div>
      </div>
    </>
  );
};

export default ApiKey;
