import { Button, Space } from "antd";
import { KycUser } from "apis/kycs.api";
import AreYouSure from "components/shared/AreYouSure";
import React from "react";
import { useChangeStatus } from "./Kyc.query";

interface Props {
  data: KycUser;
}
const KycStatusChangeButtonGroup = ({ data }: Props) => {
  const { mutate } = useChangeStatus();

  return (
    <Space>
      {(!data.fourEyes || data.fourEyes?.status === "approved") && (
        <AreYouSure
          onConfirm={() =>
            mutate({
              _id: data._id,
              status: "approve",
            })
          }
        >
          <Button type="primary" size="large">
            Approve
          </Button>
        </AreYouSure>
      )}
      {(!data.fourEyes || data.fourEyes?.status === "suspended") && (
        <AreYouSure
          onConfirm={() =>
            mutate({
              _id: data._id,
              status: "suspend",
            })
          }
        >
          <Button
            type="primary"
            size="large"
            className="bg-red-400 hover:bg-red-600"
          >
            Suspend
          </Button>
        </AreYouSure>
      )}
      {(!data.fourEyes || data.fourEyes?.status === "declined") && (
        <AreYouSure
          onConfirm={() =>
            mutate({
              _id: data._id,
              status: "decline",
            })
          }
        >
          <Button type="primary" size="large" danger>
            Decline
          </Button>
        </AreYouSure>
      )}
    </Space>
  );
};

export default KycStatusChangeButtonGroup;
