import React from "react";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";

interface Props {
  value?: boolean;
}

const BooleanToCircle = ({ value }: Props) => {
  if (value === null || value === undefined) {
    return <></>;
  }
  return (
    <>
      {value ? (
        <CheckCircleTwoTone twoToneColor="#0F0" className="text-xl m-0" />
        ) : (
        <ExclamationCircleTwoTone twoToneColor="#F00" className="text-xl m-0" />
      )}
    </>
  );
};

export default BooleanToCircle;
