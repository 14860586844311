import { adminAxiosInstance } from "./axiosInstance";

export interface LoginRequestBody {
  email: string;
  password: string;
}

export interface AcceptInviteDto {
  email: string;
  password: string;
  partnerId: string;
  uid: string;
}

export interface ValidateEmailCodeRequestBody {
  email: string;
  password: string;
  emailCode: string;
}

export interface AdminUser {
  email: string;
  partnerId: string | null;
  superuser: boolean;
  roles: string[];
}

export interface Invite {
  email: string;
  partnerId: string;
}

// Backend API methods

export const login = async (params: LoginRequestBody): Promise<any> =>
  adminAxiosInstance.post(
    "auth/login",
    { ...params },
    { withCredentials: false }
  );

export const validateEmailCode = async (
  params: ValidateEmailCodeRequestBody
): Promise<any> =>
  adminAxiosInstance.post("auth/validateEmailCode", { ...params });

export const me = async (): Promise<AdminUser> =>
  (await adminAxiosInstance.get("auth/me")).data;

export const sendInvite = async (params: AdminUser): Promise<any> =>
  adminAxiosInstance.post("invite", { ...params });

export const findInvite = async (uid: string): Promise<Invite> =>
  (await adminAxiosInstance.get(`invite/${uid}`)).data;

export const registerAdmin = async (params: AcceptInviteDto): Promise<any> =>
  adminAxiosInstance.post("invite/accept", { ...params });
