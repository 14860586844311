import Register from "components/pages/register/Register";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import MainLayout from "./components/layout/MainLayout";
import Login from "./components/pages/login/Login";

const App = () => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="*" component={MainLayout} />
    </Switch>
  );
};

export default App;
