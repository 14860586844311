import { Badge, Descriptions, Popover } from "antd";
import { KycStatus, KycUser } from "apis/kycs.api";
import BooleanToCircle from "components/shared/BooleanToCircle";
import * as _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { descriptionBackgroundColor } from "utils/constants";
import { localDateTime } from "utils/helpers";

interface Props {
  data: KycUser;
}

const livenessPopoverContent = (
  <div>
    <div>
      During the KYC process, we ask the user to prove, she is a live person.
    </div>
    <div>
      To ensure this, we ask her to turn her face left, right, or smile in a
      random order.
    </div>
  </div>
);

const guessedLivenessPopoverContent = (
  <div>
    <div>
      We'd like to speed up the checking process with an automated solution.
    </div>
    <div>
      After she submitted 3 images, our{" "}
      <span className="font-mono text-pink-300">LivenessAI</span> system tries
      to guess, if she submitted in the requested order.
    </div>
  </div>
);

const livenessResultPopoverContent = (
  <div>
    <div>
      Result of <span className="font-mono text-pink-300">LivenessAI</span>{" "}
      system check process.
    </div>
  </div>
);

const convertStatusToBadgeStatus = (
  status?: KycStatus
): "error" | "success" | "warning" | "processing" | "default" => {
  switch (status) {
    case "approved":
      return "success";
    case "declined":
      return "error";
    case "pending":
      return "processing";
    case "suspended":
      return "warning";
    default:
      return "default";
  }
};

const convertLivenessOrderToEmoji = (order: string) => {
  switch (order) {
    case "left":
      return "👈";
    case "right":
      return "👉";
    case "smile":
      return "😊";
    case "unknown":
      return "❓";
    default:
      return "";
  }
};

const KycData = ({ data }: Props) => {
  return (
    <Descriptions bordered labelStyle={descriptionBackgroundColor} column={1}>
      {data.supervisor && (
        <Descriptions.Item label="Supervisor">
          <Link to={`/kyc/${data.supervisor}`}>{data.supervisor}</Link>
        </Descriptions.Item>
      )}
      <Descriptions.Item label="Status">
        <Badge
          status={convertStatusToBadgeStatus(data.status)}
          text={data.status}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Politcally Exposed Person (PEP)">
        {(data.pep === false || data.pep === true) && (
          <BooleanToCircle value={!data.pep} />
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Requested liveness order">
        <Popover
          content={livenessPopoverContent}
          title="What is this?"
          trigger="hover"
        >
          {data.livenessOrder?.map(convertLivenessOrderToEmoji)}
        </Popover>
      </Descriptions.Item>
      <Descriptions.Item label="Our guess in liveness order">
        <Popover
          content={guessedLivenessPopoverContent}
          title="What is this?"
          trigger="hover"
        >
          {data.predictedLivenessOrder?.map(convertLivenessOrderToEmoji)}
        </Popover>
      </Descriptions.Item>
      <Descriptions.Item label="LivenessAI check result">
        <Popover
          content={livenessResultPopoverContent}
          title="What is this?"
          trigger="hover"
        >
          {!_.isEmpty(data.predictedLivenessOrder) && (
            <BooleanToCircle
              value={_.isEqual(data.livenessOrder, data.predictedLivenessOrder)}
            />
          )}
        </Popover>
      </Descriptions.Item>
      <Descriptions.Item label="Created at">
        {localDateTime(data.createdAt)}
      </Descriptions.Item>
      <Descriptions.Item label="Updated at">
        {localDateTime(data.updatedAt)}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default KycData;
