import { motion } from "framer-motion";
import React from "react";
import { Route, RouteProps } from "react-router-dom";

const AnimatedRoute = (props: RouteProps & { protected?: boolean }) => {
  return (
    <Route {...props}>
      <motion.div
        initial={{ scaleY: 0.5, scaleX: 0.5 }}
        animate={{ scaleY: 1, scaleX: 1 }}
        exit={{ scaleY: 0.5, scaleX: 0.5 }}
        transition={{ duration: 0.1 }}
      >
        {props.children}
      </motion.div>
    </Route>
  );
};

export default AnimatedRoute;
