import { Popconfirm } from "antd";
import React from "react";

interface Props {
  children: React.ReactNode;
  onConfirm: () => void;
}

const AreYouSure = (props: Props) => {
  return (
    <Popconfirm
      placement="top"
      title="Are you sure?"
      okText="Yes"
      cancelText="No"
      onConfirm={props.onConfirm}
    >
      {props.children}
    </Popconfirm>
  );
};

export default AreYouSure;
