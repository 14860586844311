import { Table } from "antd";
import { KycStatus, KycUser } from "apis/kycs.api";
import { useKycUsers } from "components/pages/kyc/Kyc.query";
import BooleanToCircle from "components/shared/BooleanToCircle";
import ErrorResult from "components/shared/ErrorResult";
import PageTitle from "components/shared/PageTitle";
import dayjs from "dayjs";
import useSearchParams from "hooks/useSearchParams";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { localDateTime } from "utils/helpers";
import * as _ from "lodash";

interface Props {}

const columns = [
  {
    title: "Id",
    dataIndex: "_id",
    sorter: (a: KycUser, b: KycUser) => a._id.localeCompare(b._id),
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: (a: KycUser, b: KycUser) => a.status.localeCompare(b.status),
  },
  {
    title: "PEP",
    dataIndex: "pep",
    render: (value: any, record: KycUser, index: number) => (
      <>
        {(value === false || value === true) && (
          <BooleanToCircle value={!value} />
        )}
      </>
    ),
  },
  {
    title: "LivenessAI check",
    render: (value: any, record: KycUser, index: number) => (
      <>
        {!_.isEmpty(record.livenessOrder) && (
          <>
            <BooleanToCircle
              value={_.isEqual(
                record.livenessOrder,
                record.predictedLivenessOrder
              )}
            />
          </>
        )}
      </>
    ),
  },
  {
    title: "Supervisor",
    dataIndex: "supervisor",
    sorter: (a: KycUser, b: KycUser) =>
      (a.supervisor || "").localeCompare(b.supervisor || ""),
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    render: (value: any) => {
      return localDateTime(value);
    },
    sorter: (a: KycUser, b: KycUser) => dayjs(a.createdAt).diff(b.createdAt),
  },
  {
    title: "Updated at",
    dataIndex: "updatedAt",
    render: (value: any) => {
      return localDateTime(value);
    },
    sorter: (a: KycUser, b: KycUser) => dayjs(a.updatedAt).diff(b.updatedAt),
  },
];

const Kyc = (props: Props) => {
  const status = useSearchParams().get("status") as KycStatus;
  const history = useHistory();
  const { data, error, isError, isFetching, refetch } = useKycUsers(status);

  useEffect(() => {
    refetch();
  }, [status, refetch]);

  if (isError) {
    return <ErrorResult error={error} />;
  }

  return (
    <>
      <PageTitle text="List of KYC objects" />
      <Table
        columns={columns}
        dataSource={data}
        loading={isFetching}
        rowKey={(record) => record._id}
        pagination={{ pageSize: 20 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              history.push(`/kyc/${record._id}`);
            },
          };
        }}
      />
    </>
  );
};

export default Kyc;
