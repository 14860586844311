import { Table, Tag } from "antd";
import { AdminUser } from "apis/auth.api";
import ErrorResult from "components/shared/ErrorResult";
import PageTitle from "components/shared/PageTitle";
import React from "react";
import DeleteUser from "./DeleteUser";
import { useUsers } from "./UserAdministration.query";
import UserRolesEditor from "./UserRolesEditor";

const maxRolesLength = 5;
const columns = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: (a: AdminUser, b: AdminUser) => a.email.localeCompare(b.email),
  },
  {
    title: "Partner ID",
    dataIndex: "partnerId",
    key: "partnerId",
    sorter: (a: AdminUser, b: AdminUser) =>
      (a.partnerId || "").localeCompare(b.partnerId || ""),
  },
  {
    title: "Roles",
    key: "roles",
    dataIndex: "roles",
    render: (roles: string[]) => {
      if (!roles) {
        return <span>No roles yet</span>;
      }
      return (
        <span>
          {roles.slice(0, maxRolesLength).map((role) => (
            <Tag color="green" key={role}>
              {role}
            </Tag>
          ))}
          {roles.length > maxRolesLength ? "..." : ""}
        </span>
      );
    },
  },
  {
    title: "Actions",
    key: "actions",
    render: (text: string, record: AdminUser) => <DeleteUser record={record} />,
  },
];

interface Props {}

const UserAdministration = (props: Props) => {
  const { indexedData, isError, error, isFetching } = useUsers();

  if (isError) {
    return <ErrorResult error={error} />;
  }

  return (
    <>
      <PageTitle text="Admin user administration" />
      <Table
        columns={columns}
        dataSource={indexedData}
        loading={isFetching}
        rowKey={(record) => record.email}
        pagination={{ pageSize: 20 }}
        expandable={{
          expandedRowRender: (record) => <UserRolesEditor user={record} />,
        }}
      />
    </>
  );
};

export default UserAdministration;
