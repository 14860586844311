export enum ServerState {
  KYC_USERS = "kycUsers",
  KYC_USER = "kycUser",
  ADMIN_USER = "adminUser",
  ADMIN_USER_FULL = "adminUserFull",
  ADMIN_USERS = "adminUsers",
  ADMIN_USER_NOTIFICATIONS = "adminUserNotifications",
  ROLES = "roles",
  INVITE = "invite",
  APP_USERS = "appUsers",
  APP_USER = "appUser",
  APP_USER_CARDS = "appUserCards",
  APP_USER_BALANCE = "appUserBalance",
  APP_USER_RELATIONS = "appUserRelations",
}
