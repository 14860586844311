import { LinkOutlined } from "@ant-design/icons";
import { Button } from "antd";
import PageTitle from "components/shared/PageTitle";
import React from "react";

interface Props {}

const Documentation = (props: Props) => {
  return (
    <>
      <PageTitle text="API Documentation" />
      <div className="m-auto w-full flex justify-center mt-5">
        <Button
          type="primary"
          shape="round"
          size="large"
          href="https://documenter.getpostman.com/view/14820346/UUxuipnX"
          target="_blank"
          icon={<LinkOutlined />}
        >
          Open API Documentation
        </Button>
      </div>
    </>
  );
};

export default Documentation;
