import { kycAxiosInstance } from "./axiosInstance";

export type KycStatus =
  | "initiated"
  | "pending"
  | "approved"
  | "declined"
  | "suspended";

export interface KycFourEyes {
  approvers: string[];
  status: KycStatus;
  userToken: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface PersonalData {
  _id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  homeAddress: Address;
  postalAddress: Address;
  createdAt: Date;
  updatedAt: Date;
}

export interface ImageWithExtract {
  url: string;
  extractedText?: string;
}

export interface KycUser {
  _id: string;
  partnerId: string;
  status: KycStatus;
  relatedTo: PersonalData;
  natIdFront?: ImageWithExtract;
  natIdBack?: ImageWithExtract;
  passport?: ImageWithExtract;
  driving?: ImageWithExtract;
  selfie?: ImageWithExtract;
  address?: ImageWithExtract;
  pep?: boolean;
  supervisor?: string;
  fourEyes?: KycFourEyes;
  livenessValidity?: Date;
  livenessOrder?: string[];
  predictedLivenessOrder?: string[];
  livenessImages?: string[];
  createdAt: Date;
  updatedAt: Date;
}

export interface Address {
  countryCode: string;
  city: string;
  postalCode: string;
  addressLine1: string;
  addressLine2: string;
}

export interface ChangeStatusQuery {
  _id: string;
  status: "approve" | "suspend" | "decline";
}

export const getKycUsers = async (status?: KycStatus): Promise<KycUser[]> => {
  if (status) {
    return (await kycAxiosInstance.get(`kycs?status=${status}`)).data;
  } else {
    return (await kycAxiosInstance.get(`kycs`)).data;
  }
};

export const getKycUser = async (_id: string): Promise<KycUser> =>
  (await kycAxiosInstance.get(`kycs/${_id}`)).data;

export const changeStatus = async ({ _id, status }: ChangeStatusQuery) =>
  kycAxiosInstance.put(`kycs/${_id}/${status}`);

export const deleteFourEyes = async (userToken: string) =>
  kycAxiosInstance.delete(`kycs/fourEyes/${userToken}`);
