import { Alert, Button, Form, Input, message, Select } from "antd";
import PageTitle from "components/shared/PageTitle";
import React from "react";

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select style={{ width: 90 }} defaultValue="https://">
      <Select.Option value="http://">http://</Select.Option>
      <Select.Option value="https://">https://</Select.Option>
    </Select>
  </Form.Item>
);

interface Props {}

const Webhook = (props: Props) => {
  return (
    <>
      <PageTitle text="Webhook" />
      <Alert
        type="info"
        className="text-center text-xl my-10"
        message="Coming soon!"
      />
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onSubmitCapture={() => message.info(`It's coming, we promise 😇`)}
      >
        <Form.Item label="KYC initiated callback">
          <Input disabled addonBefore={prefixSelector} />
        </Form.Item>
        <Form.Item label="KYC approved callback">
          <Input disabled addonBefore={prefixSelector} />
        </Form.Item>
        <Form.Item label="KYC suspended callback">
          <Input disabled addonBefore={prefixSelector} />
        </Form.Item>
        <Form.Item label="KYC declined callback">
          <Input disabled addonBefore={prefixSelector} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4 }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Webhook;
