import { Descriptions } from "antd";
import { PersonalData } from "apis/kycs.api";
import React from "react";
import { descriptionBackgroundColor } from "utils/constants";
import { getDisplayAddress, localDateTime, localDate } from "utils/helpers";

interface Props {
  data: PersonalData;
}

const KycPersonalData = ({ data }: Props) => {
  return (
    <Descriptions bordered labelStyle={descriptionBackgroundColor} column={1}>
      <Descriptions.Item label="First name">{data.firstName}</Descriptions.Item>
      <Descriptions.Item label="Last name">{data.lastName}</Descriptions.Item>
      <Descriptions.Item label="Date of Birth">
        {localDate(data.dateOfBirth)}
      </Descriptions.Item>
      <Descriptions.Item label="Home address">
        {getDisplayAddress(data.homeAddress)}
      </Descriptions.Item>
      <Descriptions.Item label="Postal address">
        {getDisplayAddress(data.postalAddress)}
      </Descriptions.Item>
      <Descriptions.Item label="Created at">
        {localDateTime(data.createdAt)}
      </Descriptions.Item>
      <Descriptions.Item label="Updated at">
        {localDateTime(data.updatedAt)}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default KycPersonalData;
