import { message } from "antd";
import { AdminUser } from "apis/auth.api";
import { deleteUser, getRoles, getUsers, saveRoles } from "apis/users.api";
import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ServerState } from "utils/serverState";

export const useUsers = () => {
  const ret = useQuery<AdminUser[], AxiosError>(ServerState.ADMIN_USERS, () =>
    getUsers()
  );

  const indexedData = ret.data?.map((d, idx) => ({ key: idx, ...d }));
  return { indexedData, ...ret };
};

export const useRoles = () => {
  const roles = useQuery<string[], AxiosError>(ServerState.ROLES, () =>
    getRoles()
  );
  const rolesDataSource = roles.data?.map((r, idx) => ({ key: r, title: r }));
  return { rolesDataSource, ...roles };
};

export const useUpdateRoles = () => {
  const queryClient = useQueryClient();

  return useMutation((o: { email: string; roles: string[] }) => saveRoles(o), {
    onSuccess: (data, vars) => {
      queryClient.invalidateQueries(ServerState.ADMIN_USERS);
      message.success(`Roles saved for ${vars.email}`);
    },
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  return useMutation((o: { email: string }) => deleteUser(o.email), {
    onSuccess: (data, vars) => {
      queryClient.invalidateQueries(ServerState.ADMIN_USERS);
      message.success(`${vars.email} successfully deleted`);
    },
  });
};
