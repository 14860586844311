import { RedoOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Image, Row } from "antd";
import { useDeleteFourEyes, useKycUser } from "components/pages/kyc/Kyc.query";
import AreYouSure from "components/shared/AreYouSure";
import CenteredSpin from "components/shared/CenteredSpin";
import ErrorResult from "components/shared/ErrorResult";
import PageTitle from "components/shared/PageTitle";
import dayjs from "dayjs";
import React from "react";
import Highlighter from "react-highlight-words";
import { useParams } from "react-router-dom";
import { removeAccentuatedChars } from "utils/helpers";
import { resolveImageUrl } from "utils/kycImageUrlResolver";
import KycApproverSteps from "./KycApproverSteps";
import KycData from "./KycData";
import "./KycDetails.css";
import KycPersonalData from "./KycPersonalData";
import KycStatusChangeButtonGroup from "./KycStatusChangeButtonGroup";

interface Props {}

const KycDetails = (props: Props) => {
  const { userToken } = useParams<{ userToken: string }>();
  const { isFetching, isError, data, images, error } = useKycUser(userToken);
  const { mutate } = useDeleteFourEyes();

  if (isFetching) {
    return <CenteredSpin />;
  }

  if (isError) {
    return <ErrorResult error={error} />;
  }

  const personalData = data!.relatedTo;

  return (
    <>
      <PageTitle text="KYC Details" />
      <div className="flex justify-between mb-2 xl:space-x-20 xl:flex-row flex-col">
        <div className="flex-grow flex flex-row space-x-5 items-center bg-visa-blue/20 p-3 border-gray-800 border mb-2 xl:mb-0">
          <KycApproverSteps fourEyes={data!.fourEyes} />
          {data?.status === "pending" && data?.fourEyes && (
            <AreYouSure onConfirm={() => mutate(userToken)}>
              <Button
                type="primary"
                danger
                size="middle"
                icon={<RedoOutlined />}
              >
                Restart four eye principle
              </Button>
            </AreYouSure>
          )}
        </div>
        {data?.status === "pending" && (
          <KycStatusChangeButtonGroup data={data} />
        )}
      </div>
      <Row gutter={16}>
        <Col xl={8} xs={24}>
          <Divider orientation="left">Personal data</Divider>
          <KycPersonalData data={personalData} />
          <Divider orientation="left">KYC data</Divider>
          <KycData data={data!} />
        </Col>
        <Col xl={16} xs={24}>
          <Divider orientation="left">KYC images</Divider>
          <Row gutter={16}>
            {images.map((imgWithExtract) => (
              <Col xl={6} lg={12} xs={24}>
                <div>
                  <Image
                    alt="kyc document"
                    src={resolveImageUrl(imgWithExtract.url)}
                    key={imgWithExtract.url}
                    className="max-h-96"
                    placeholder={true}
                  />
                  <div className="p-3 bg-visa-blue/20">
                    <Highlighter
                      highlightClassName="text-pink-500"
                      sanitize={removeAccentuatedChars}
                      searchWords={[
                        personalData.firstName,
                        personalData.lastName,
                        dayjs(personalData.dateOfBirth).format("DD MM YYYY"),
                        dayjs(personalData.dateOfBirth).format("YYYY.MM.DD"),
                        personalData.homeAddress.countryCode,
                        personalData.homeAddress.city,
                        personalData.homeAddress.postalCode,
                        personalData.homeAddress.addressLine1,
                        personalData.homeAddress.addressLine2,
                      ]}
                      autoEscape={true}
                      textToHighlight={imgWithExtract.extractedText || ""}
                    />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Divider orientation="left">Liveness images</Divider>
          <Row gutter={16}>
            {data?.livenessImages?.map((img) => (
              <Col xl={8} md={12} xs={24}>
                <Image
                  alt="liveness"
                  src={resolveImageUrl(img)}
                  key={img}
                  className="max-h-96"
                  placeholder={true}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default KycDetails;
