import { Button, Transfer } from "antd";
import { TransferDirection } from "antd/lib/transfer";
import { AdminUser } from "apis/auth.api";
import React from "react";
import { useList } from "react-use";
import { useRoles, useUpdateRoles } from "./UserAdministration.query";

interface Props {
  user: AdminUser;
}

const UserRolesEditor = ({ user }: Props) => {
  const roles = useRoles();
  const { mutate } = useUpdateRoles();
  const [selectedKeys, { push, clear }] = useList<string>([]);
  const [targetKeys, { set }] = useList<string>(user.roles);

  const saveRoles = () => {
    mutate({ email: user.email, roles: targetKeys });
  };

  const onSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    clear();
    push(...sourceSelectedKeys);
    push(...targetSelectedKeys);
  };

  const onChange = (
    keys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    set(keys);
  };

  return (
    <div className="space-y-4">
      <Transfer
        dataSource={roles.rolesDataSource}
        titles={["System Roles", "Owned Roles"]}
        listStyle={{ width: "50%" }}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={onChange}
        onSelectChange={onSelectChange}
        render={(item) => item.title}
      />
      <Button type="primary" onClick={saveRoles}>
        Save
      </Button>
    </div>
  );
};

export default UserRolesEditor;
