import { Button, Col, Form, Input, message, Row, Typography } from "antd";
import { AdminUser, sendInvite } from "apis/auth.api";
import CenteredSpin from "components/shared/CenteredSpin";
import ErrorResult from "components/shared/ErrorResult";
import PageTitle from "components/shared/PageTitle";
import { useMe } from "hooks/Common.query";
import React from "react";
import { displayErrorMessage } from "utils/messageHelper";

interface Props {}

const Invite = (props: Props) => {
  const { data, isError, error, isFetching } = useMe();

  if (isError) {
    return <ErrorResult error={error} />;
  }

  if (isFetching) {
    return <CenteredSpin />;
  }

  const onFinish = (values: AdminUser) => {
    sendInvite(values)
      .then(() => message.success("Invite sent successfully"))
      .catch(displayErrorMessage);
  };

  return (
    <>
      <PageTitle text="Send invitation" />
      <Row gutter={24}>
        <Col xs={24} lg={12}>
          <Form
            initialValues={{ partnerId: data?.partnerId }}
            onFinish={onFinish}
            labelCol={{ span: 8 }}
            className="flex-1"
          >
            <Form.Item
              label="Email address"
              name="email"
              rules={[
                { required: true, message: "Please input email address!" },
              ]}
            >
              <Input type="email" />
            </Form.Item>

            <Form.Item label="Partner ID" name="partnerId">
              <Input disabled={!data?.superuser} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8 }}>
              <Button type="primary" htmlType="submit">
                Invite
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} lg={12}>
          <div className="bg-gray-800 px-12 py-8">
            <Typography.Text>
              You can invite your team members to your organization (identified
              by <span className="text-red-300">Partner ID</span>). <br />
              They'll get an invitation mail with a link to our registration
              screen, where they have to provide a password. <br />
              An invitation is valid for{" "}
              <span className="text-red-300">7 days</span> and can be used only
              once.
            </Typography.Text>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Invite;
