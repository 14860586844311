import { yupResolver } from "@hookform/resolvers/yup";
import { registerAdmin } from "apis/auth.api";
import clsx from "clsx";
import CenteredSpin from "components/shared/CenteredSpin";
import ErrorResult from "components/shared/ErrorResult";
import { motion } from "framer-motion";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { displayErrorMessage } from "utils/messageHelper";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { useInvite } from "./Register.query";

interface Props {
  uid: string;
}

interface Inputs {
  email: string;
  password: string;
  confirmPassword: string;
}

const schema: SchemaOf<Inputs> = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required().min(10),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "passwords must match"),
});

const RegistrationForm = ({ uid }: Props) => {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { data, isError, error, isFetching } = useInvite(uid, (d) =>
    setValue("email", d.email)
  );

  const onSubmit = (formData: Inputs) => {
    registerAdmin({
      uid,
      password: formData.password,
      ...data!,
    })
      .then(() => history.push("/login"))
      .catch(displayErrorMessage);
  };

  if (isError) {
    return <ErrorResult error={error} />;
  }

  if (isFetching) {
    return <CenteredSpin />;
  }

  return (
    <motion.form
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -300, opacity: 0 }}
      transition={{ type: "spring", stiffness: 100 }}
      onSubmit={handleSubmit(onSubmit)}
      className="sm:w-2/3 w-full px-4 lg:px-0 mx-auto"
    >
      <div className="pb-2 pt-4">
        <input
          {...register("email")}
          type="email"
          placeholder="Email"
          className={clsx(
            "block w-full p-4 text-lg rounded-sm bg-black",
            errors.email && "border-red-500 border"
          )}
        />
        <span className="text-red-500 text-sm">{errors.email?.message}</span>
      </div>
      <div className="pb-2 pt-4">
        <input
          {...register("password")}
          type="password"
          placeholder="Password"
          autoComplete="new-password"
          className={clsx(
            "block w-full p-4 text-lg rounded-sm bg-black",
            errors.password && "border-red-500 border"
          )}
        />
        <span className="text-red-500 text-sm">{errors.password?.message}</span>
      </div>
      <div className="pb-2 pt-4">
        <input
          {...register("confirmPassword")}
          type="password"
          placeholder="Confirm password"
          autoComplete="new-password"
          className={clsx(
            "block w-full p-4 text-lg rounded-sm bg-black",
            errors.confirmPassword && "border-red-500 border"
          )}
        />
        <span className="text-red-500 text-sm">
          {errors.confirmPassword?.message}
        </span>
      </div>

      <div className="px-4 pb-2 pt-4">
        <button className="uppercase block w-full p-4 text-lg rounded-full bg-indigo-500 hover:bg-indigo-600 focus:outline-none">
          register
        </button>
      </div>
    </motion.form>
  );
};

export default RegistrationForm;
