import {
  AdminNotification,
  getUnreadNotifications,
  markAllAsRead,
  markAsRead,
} from "apis/notifications.api";
import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ServerState } from "utils/serverState";

export const useMarkAllAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ServerState.ADMIN_USER_NOTIFICATIONS,
    () => markAllAsRead(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ServerState.ADMIN_USER_NOTIFICATIONS);
      },
    }
  );
};

export const useMarkAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ServerState.ADMIN_USER_NOTIFICATIONS,
    (id: string) => markAsRead(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ServerState.ADMIN_USER_NOTIFICATIONS);
      },
    }
  );
};

export const useUnreadNotifications = () =>
  useQuery<AdminNotification[], AxiosError>(
    ServerState.ADMIN_USER_NOTIFICATIONS,
    () => getUnreadNotifications(),
    {
      refetchInterval: 5000,
    }
  );
